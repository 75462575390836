import { mixinFormato } from "@/mixins/mixinFormato";
import { mixinCtrls } from "@/mixins/mixinCtrls";
import store_finder from '@/stores/modulos/store_finder.js';

export var mixinFinder = {
  mixins: [mixinFormato, mixinCtrls],
  
  data() {
    return {      
      headerActual: "",
      // para ordenación
      expandedItem:false    
    };
  },
  

  async created() {    
    if (!this.$store.state[this.stName]) this.$store.registerModule(this.stName, store_finder); 
    
    this.$store.commit('data2State', { modulo:this.stName, prop:'api', value:this.api });
    this.$store.commit('data2State', { modulo:this.stName, prop:'name', value:this.stName });
    this.$store.commit('data2State', { modulo:this.stName, prop:'componenteTipo', value:this.componenteTipo });

    //
    if (this.padre) {
      // guardo nombre del Mto actual en su componente padre
      this.$store.commit('data2State', { modulo:this.stName, prop:'padre', value:this.padre });
      this.$store.commit('data2State', { modulo:this.padre, prop:'hijo', value:this.stName });
    }
     

    // obtengo schema
    await this.$store.dispatch("get_schema", this.stName);
    console.log("DEV " + this.api + " **********MIXFINDER (created)");
    
    // configuro según el tipo de componente 
    this.ini_tipoComponente();
    
    // inicializo datos auxiliares
    this.ini_data();

    // inicializar valores controles schema.ctrls si se necesita para el filtro
    this.ini_schemaCtrls();

    // acciones al cargar el schema
    this.loadedSchema();
    
    //
    this.$watch('disparoFinder',{       
      immediate: true,
        handler() {          
          this.ini_component();            
        }
    })

    // prueba de simulacion espera 3 sg. + su tiempo en carga de schema (no debe haber errores si tarda en cargar)
    // (quito condicion if :schema del template)
    //await new Promise((resolve) => setTimeout(resolve, 3000));
    //console.log(" fin await 3000-------------------------------------------------------------------------");

    // Recuperar schema finder
    //this.schema = await this.$store.dispatch("get_schema", { tipo: "F", api: this.Entorno.api });    
    

    
    
    // ejecuto exec
    //this.$store.commit(this.stName + '/data2State', { prop:'exec', value:true });    

    // cargo registros iniciales siempre y cuando autoload sea 'true'     
    //this.get_records(this.Entorno.grid.autoload? 'ini' : '');

    //this.$emit('onEvent', { accion: 'componente_cargado' });
    // NOTA---------------------------------------------------------------------------------
    // Por si quiero retener mas cosas mientras cargo schema, pero seguir con el resto de la funcion
    // this.$store.dispatch("get_schema", {tipo: "F", name: this.Entorno.api})
    // .then((result)=>{
    //         console.log("DEV continuo haciendo cosas");
    //         this.schema=result;
    //         this.ini_data();

    //       });
  },


  //
  /* mounted() {
    this.$store.watch(
      () => this.$store.getters[this.stName + '/stExec'], exec => {        
        if (exec== false) return;

        this.$store.commit(this.stName + '/data2State', { prop:'exec', value:false });
        this.get_records(this.Entorno.grid.autoload? 'ini' : '');
      }
    )
  }, */

  //
  computed: {
        
    // devuelvo si el padre está en edición
    // Pendiente: quitar este computed y utilizar el de miStPadre
    is_padreEdit() {        
      if (!this.padre) return false;      
      return this.$store.state[this.padre].estado === "editar" || this.$store.state[this.padre].estado=== "nuevo"? true : false;
    },

    // devuelvo si el hijo está en edición
    // Pendiente: quitar este computed y utilizar el de miStHijo
    is_hijoEdit() {        
      var hijo= this.$store.state[this.stName].hijo;

      if (!hijo) return false;
      return this.$store.state[hijo].estado === "editar" || this.$store.state[hijo].estado=== "nuevo"? true : false;
    },

    rowSelected: function() { return this.$store.state[this.stName].rowSelected[0]; },   
    
    miStPadre() {
      if (!this.padre) return null;
      return this.$store.state[this.padre];
    },

    miSt() {
      if (!this.stName) return null;
      return this.$store.state[this.stName];
    },

    MX() {
      if (!this.$store.state[this.stName].hijo) return null;
      return this.$store.state[this.$store.state[this.stName].hijo];
    },

    miStHijo() {
      if (!this.$store.state[this.stName].hijo) return null;
      return this.$store.state[this.$store.state[this.stName].hijo];
    },

    miStAbuelo() {
      if (!this.miStPadre) return null;
      if (!this.miStPadre.padre) return null;
      return this.$store.state[this.miStPadre.padre];
    },

    miStNieto() {
      if (!this.miStHijo) return null;
      if (!this.miStHijo.hijo) return null;
      return this.$store.state[this.miStHijo.hijo];     
    },
    //
    //exec: function() { return this.padre? this.$store.state[this.padre].exec : false },
    //padreId: function() { return this.padre? this.$store.getters[this.padre + '/stId'] : -1 }

  },

  methods: {

    // OVERRIDE DE FUNCIONES--------------------------------------------        
    // inicializa entorno y datos auxiliar
    ini_data(){},
    // inicializa ctrls de filtro
    ini_schemaCtrls() {},
    // acciones al cargar el schema
    loadedSchema() {
      // campo de un ctrlfinder a mantener su valor cada vez que se muestra
      if (this.field2Preserve && this.schema.ctrls[this.field2Preserve]) {
        this.$set(this.schema.ctrls[this.field2Preserve], 'value', this.ctrlFGet(this.api=='atlas_F'? 'atlasF' : ''));
      }
    },
    // acciones al iniciar 'get_records'
    get_records_ini() {},
    // acciones al terminar 'get_records'
    get_records_fin() {},      
    //
    row_dblclick(evt) {      
      this.event_btra_Row({ accion: this.Entorno.grid.row.dblclick, row: evt.row });     
    },
    //-----------------------------------------------------------------

    //
    ini_tipoComponente() {  
      this.Entorno.btra= this.componenteTipo;
      switch (this.componenteTipo) {
        case 'F':
          this.Entorno.grid.row.dblclick= '';
          break;

        case 'FC':
            this.Entorno.grid.row.dblclick= 'extraer';
            break;
      }
    },


    // fn que ejecuta al saltar el watch de 'disparoFinder'
    ini_component() {
      this.get_records(this.Entorno.grid.autoload? 'ini' : ''); 
    },


    // obtengo los registros del grid  
    async get_records(accion= "") 
    {     
      console.log("DEV " + this.api + " ********** MIXFINDER  get_records", accion);
      
      // pongo el estado del Mto hijo, si lo tiene, a 'ver'
      if (this.$store.state[this.stName].hijo) this.$store.commit(this.$store.state[this.stName].hijo + '/data2State', { prop:'estado', value:'ver'});

      // si no recibo acción, blanqueo records y salgo
      if (accion=== "") {                
        this.$store.commit(this.stName + '/update_records', []);
        return;
      }      
         
      // si el id es cero, blanqueo records, emito eventos y salgo
      if (this.id== 0){
        this.$store.commit(this.stName + '/update_records', []);
       
        // emito eventos de detalle y record cargado
        this.$emit('onEvent', { accion: 'detalle_cargado' });
        this.$emit('onEvent', { accion: 'record_cargado' });
        return;
      }
        
      // añado el id recibido al schema como 'id_padre'
      this.schema.ctrls['id_padre']= { f:'id_padre', value:this.id };  

      // obtengo los datos por los que vamos a filtrar (mixinCtrls)
      var ctrls= this.par_ctrls_valor(this.schema);     

      // Pendiente: validar busqueda
      //
      
      // Pendiente: controlar errores      
      await this.$store.dispatch(this.stName + '/get_records', { ctrls:ctrls, accion:accion });
     
      // emito eventos de detalle y record cargado
      this.$emit('onEvent', { accion: 'detalle_cargado' });
      this.$emit('onEvent', { accion: 'record_cargado' });

      // acciones al terminar de obtener los registros
      this.get_records_fin();
    },    


    // búsqueda finders
    async buscarExec(accion, header) {
      console.log("DEV mixinfinder: " + this.api + " buscarExec ", accion, header);      
      this.Entorno.grid.headers = this.set_headers(header);
      this.get_records(accion);
    },


    // búsqueda finders Funescom
    async buscarExecFnc(args) {
      console.log("DEV mixinfinder: " + this.api + " buscarExecFnc ");      
      
      // obtengo records
      var apiResult= await this.$store.dispatch("ajaxRequest", { args: args });
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.error, 'error');
        return apiResult;
      }
    console.log('Records buscarExecFnc: ', apiResult);
      // guardo records
      this.$store.commit(this.stName + '/data2State', { prop:'records', value: apiResult.r[0] });
      return apiResult;
    },


    //
    set_headers(headerUpdate = "header") {
      if (headerUpdate!= this.headerActual) {
        this.headerActual = headerUpdate;        
        return this.headers[headerUpdate].map(item => ({ ...this.$cfg.styles.headersStyle, ...item }))
      }      
      return this.Entorno.grid.headers    
    },


    /* // rec
    reload_finder() {
      this.get_records(this.Entorno.grid.autoload? 'ini' : '');
    }, */
   


    // ---------------------------------------------------------------------------------------------------------------
    // --------------------------------------------------- FN DE EVENTOS ---------------------------------------------
    // ---------------------------------------------------------------------------------------------------------------
  
    // eventos de la cabercera
    event_Header(evt) {
      console.log("DEV " + this.Entorno.api + " event_Header ********** MIXFINDER", evt);
      
      if (this.$isFunction(this[evt.accion])) return this[evt.accion](evt);
      
      if (this.componenteTipo== "FC") {
        this.$emit('onEvent', evt);
        return;
      }

      this.$router.push({ path: '/' });
      this.$store.commit("set_activeMenu", []);
    },

    // eventos del Grid
    event_Grid(evt) {
      console.log("DEV " + this.Entorno.api + " event_Grid ********** MIXFINDER", evt);
      if (evt.accion=== 'linea_en_edicion') return this.linea_en_edicion();

      this.event_btra_Row(evt);
    },

    // eventos botonera encima del Grid
    event_btra_Top(evt) {
      console.log("DEV " + this.Entorno.api + " event_btra_Top ********** MIXFINDER", evt); 
      if (evt.accion=== 1) { // nuevo
        if (this.miStHijo && (this.miStHijo.estado== 'editar' || this.miStHijo.estado== 'nuevo')) return this.linea_en_edicion();
        
        this.event_btra_Row({ accion:evt.accion, row:{ id:new Date().getTime() } });
        return;
      }   

      if (this.$isFunction(this[evt.accion])) return this[evt.accion](evt);
    },

    // eventos botonera acciones lineas Grid
    event_btra_Row(evt) {
      console.log("DEV " + this.Entorno.api + " event_btra_Row ********** MIXFINDER", evt);
      
      // compruebo si existe la función. Si existe la ejecuto
      if (this.$isFunction(this[evt.accion])) return this[evt.accion](evt);

      // extraer
      if (evt.accion=== 'extraer') {
          this.$emit('onEvent', evt)
          return;
      }
      
      // dblclick
      if (evt.accion=='dblclick') return this.row_dblclick(evt);       
      
      // compruebo si la acción es de un Mto
      // (0:'ver', 1:'nuevo', 2:'editar', 3:'borrar', 4:'guardar', 5:'cancelar', 6:'salir')
      const mtoOptions= ['ver', 'nuevo', 'editar', 'borrar', 'guardar', 'cancelar', 'salir'];
      let accion= (/^([0-9])*$/.test(evt.accion))? mtoOptions[evt.accion] : null;
      if (!accion) return;
      
      // ver, editar          
      this.reactividad_M(evt);
      this.reactividad_MX(evt);   
    },

    // event del Mto del Finder
    event_Mto(evt) {
      console.log("DEV " + this.Entorno.api + " event_Mto ********** MIXFINDER", evt); 
      this.btra_Mto(evt);
    },

    // gestor de eventos del expansible
    event_expansible(evt) {     
      console.log("DEV " + this.Entorno.api + " event_expansible ********** MIXFINDER", evt);

      // evento indicando que una linea de un XM_array se ha modificado (guardar y eliminar)
      if (evt.accion== "detalle_modificado") {
        this.$emit('onEvent', evt); 
        // se supone que si graba cierra expansible, pongo chivato a false
        this.expandedItem=false;
        //
        return;
      }

      // compruebo si existe la función
      if (this.$isFunction(this[evt.accion])) return this[evt.accion](evt);    
      this.btra_Mto(evt);      
    },   

    //
    linea_en_edicion() {
      this.$root.$alert.open("Está editando una fila", "error");
    },    

    //
    btra_Mto(evt) {
      console.log("DEV " + this.Entorno.api + " btra_Mto ********** MIXFINDER", evt);
            
      switch (evt.accion) {
 
        case 0: // ver
          //alert('Ver mixinFinder');       
          this.Entorno.estado= 'ver';          
          break;
  
        case 1: // nuevo 
          //alert('Nuevo mixinFinder');
          this.Entorno.estado= 'nuevo';
          this.Entorno.grid.row.selected_items=[{}];          
          break;
  
        case 2: // editar
          //alert('Editar mixinFinder');
          this.Entorno.estado= 'editar';
          break;
  
        case 3: // borrar
          //alert('Borrar mixinFinder');
          this.Entorno.estado= 'ver';    
          this.Entorno.grid.row.selected_items= [];
          // recargo finder    
          break;
  
        case 4: // guardar
          //alert('Guadar mixinFinder');
          this.Entorno.estado= 'ver';
          this.Entorno.grid.row.selected_items= [];
          // recargo finder   
          break;
  
        case 5: // cancelar          
          if (this.Entorno.grid.row.expansible) {
            this.$store.state[this.stName].records.shift();
            this.$store.commit(this.stName + '/data2State', { prop:'rowSelected', value:[] });
          }
         
          this.modal= false;
          break;
  
        case 6: // salir            
          this.modal= false;
          break;
      }
      //this.$emit('onEvent', { source:"finder_hijo", accion:accion, row:row });
    },

    



    //-----------------------------------------------------------------------------------------------------------------------
    // ---------- ACCIONES MODAL --------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    // acciones asociadas a los eventos del Mto expansible
   
    // reactividad_M
    // id y acción
    reactividad_M(evt, show_M= true) {
      if (this.Entorno.grid.row.expansible) return;

      console.log('Reactividad_M: ', evt, show_M);     
      
      // guardo acción del btn recibido y la fila seleccionada
      this.accion= evt.accion;
      this.accionRow= evt.row;
      this.disparo= !this.disparo;
            
      // muestro / cierro modal 
      this.modal = show_M;
    },
    

    // reactividad Mto expansible
    // id y acción
    reactividad_MX(evt) {
      if (!this.Entorno.grid.row.expansible) return;
      
      console.log('reactividad_MX: ', evt);
      
      // si es un nuevo, inserto linea vacía en records y selecciono fila
      if (evt.accion== 1) {
        this.$store.commit(this.stName + '/update_record', { opcion:'nuevo', record:[], id: evt.row.id });
      }

      // guardo acción del btn recibido y la fila seleccionada
      this.accion= evt.accion;       
      this.disparo= !this.disparo;
    },
   

    //
    validar_busqueda(){
      // valido controles
      //if (name_btnPulsado !== "ini") {

        // valido campos
        /* if (ctrlsFiltro[name_btnPulsado].fields2validate) {
            // monto array con los controles a validar                   
            var fields2validate= data.ctrlsFiltro[name_btnPulsado].fields2validate;
            var ctrls= {};

            for (var x=0; x< fields2validate.length; x++) {
                ctrls[fields2validate[x]]= data.ctrlsFiltro[fields2validate[x]];
            }
            
            // valido controles
            if (!this.$validate_fields(ctrls)) return;
            
        }

        // establezo la cabecera del grid según lo definido en el schema del btn pulsado
        if (ctrlsFiltro[name_btnPulsado].header) {
            //this.sGrid.headers= this.sGrid[ctrlsFiltro[name_btnPulsado].header];
        }              */
      //}

      return true;
    },


    //
    ctrlFGet(modulo= this.api) {     
      console.log('ctrlFGet:::', modulo);
      return this.$store.getters["ctrlFGet"](modulo);
    },

    
  },


}
