<!-- Comunicados Expediente -->

<template>
  <div class="exp_Comunicados">
    <v-dialog v-model="dialog" content-class="modal" persistent>
      <template v-slot:activator="{on}">
        <!-- Botón para abrir Finder Documentos -->
        <v-btn 
          v-on='on'
          v-bind="$cfg.btra_cfg.standard" 
          v-on:click="get_records('ini')"
          title="Comunicados"
          v-show="show=='1'">
            <v-icon dark>{{ "mdi-nfc-tap" }}</v-icon>
        </v-btn>       
      </template>

      <div class="centrado" v-if="schema">  
        <v-sheet elevation="4">

          <!-- Cabecera -->
          <base_Header 
            :Entorno="Entorno.header"
            @onEvent="cerrar()">
          </base_Header>         

          <!-- Filtro de Busqueda -->        
          <div class="contenedor" :style="Entorno.filtro.style" :class="Entorno.filtro.class">
            <div style="display:flex">
              <div class="columna" style="width:40%">          
                <div class="cab">EXPEDIENTE</div>
                <v-sheet v-bind="$cfg.styles.marco">                
                  <v-text-field
                    style="flex: 0 0 30px"
                    v-bind="$input"
                    v-model="accionRow.control"
                    label="Nº Control"
                    readonly>
                  </v-text-field>                
                               
                  <v-text-field
                    v-bind="$input"
                    v-model="accionRow.fallename"
                    label="Nombre"
                    readonly>
                  </v-text-field>  
                  
                  <v-text-field
                    v-bind="$input"
                    v-model="fallefhhr"
                    label="Fecha/Hora"
                    readonly>
                  </v-text-field>                
                </v-sheet>          
              </div>

              <div class="columna" style="width:60%">          
                <div class="cab">COMPAÑÍA</div>
                <v-sheet height="140px" v-bind="$cfg.styles.marco">                
                  <v-text-field
                    v-bind="$input"
                    v-model="accionRow.name_callemp"
                    label="CIA"
                    readonly>
                  </v-text-field>  

                  <v-text-field
                    v-bind="$input"
                    v-model="accionRow.name_agencia"
                    label="Agencia"
                    readonly>
                  </v-text-field> 

                  <v-text-field
                    v-bind="$input"
                    v-model="accionRow.name_fune1"
                    label="Funeraria"
                    readonly>
                  </v-text-field>                
                </v-sheet>          
              </div>
            </div>
          </div>

          <!-- Contenido -->     
          <div class="conflex">
            <div class="columna" style="width:890px">
              <!-- <div class="cab" style="width:890px">DETALLE</div> -->

              <!-- Grid -->
              <base_Fgrid 
                :padre="stName" 
                :Entorno="Entorno" 
                show-expand
                :items-per-page=-1
                @onEvent="event_Grid">
                <!-- Slot botonera Top -->
                <template v-slot:top_btns="{ Sitem }">         
                  <div style="padding:10px 0 0 10px;">
                    <btracombi                 
                      :btra_permisos="schema.btra"
                      :btra_particular="Entorno.btra"
                      btra_modulo="top"
                      @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
                    </btracombi>
                  </div>
                </template>  

                <!-- Slot fhhr -->
                <template v-slot:fhhr="{ Sitem }">               
                  {{ Sitem.fhhr|fechahr }}
                  <!-- </div> -->
                  <div v-if="Sitem.usu" class="light-blue--text text--darken-3"> {{ Sitem.usu }}</div>                  
                </template> 

                <!-- Slot emp -->
                <template v-slot:emp="{ Sitem }">               
                  {{ Sitem.emp }}
                  <!-- </div> -->
                  <div v-if="Sitem.ate" class="light-blue--text text--darken-3"> {{ Sitem.ate }}</div>                  
                </template>  

                <!-- Slot name_env -->
                <template v-slot:name_env="{ Sitem }">               
                  {{ Sitem.name_env }}
                  <!-- </div> -->
                  <div v-if="Sitem.name_tip" class="light-blue--text text--darken-3"> {{ Sitem.name_tip }}</div>                  
                </template>

                <!-- Slot campo acciones del Grid -->
                <template v-slot:acciones="{ Sitem }">
                  <btracombi
                    :btra_permisos="schema.btra"
                    :btra_particular="Entorno.btra"
                    btra_modulo="mrow"
                    @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
                  </btracombi>                
                </template> 

                <!-- Slot panel expansible -->
                <template v-slot:expansible_ctrls="{}">
                  <exp_comunicados_XM               
                    :padre="stName"
                    :accion="accion"
                    :accionRow="rowSelected"
                    :auxRecord="accionRow"
                    :disparo="disparo"
                    @onEvent="event_expansible">
                  </exp_comunicados_XM>
                </template>            
              </base_Fgrid>
            </div>
          </div>
        </v-sheet>
      </div>
    </v-dialog>
  </div>
</template>


 
<script>

    import { mixinFinder } from "@/mixins/mixinFinder.js";    
    import plugs from "@/common/general_plugs";    
    const base_Header = () => plugs.groute("base_Header.vue", "base"); 
    const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
    const btracombi = () => plugs.groute("btracombi.vue", "comp");  
    const exp_comunicados_XM = () => plugs.groute("exp_comunicados_XM.vue", "comp");  
        
    export default {
      mixins: [mixinFinder],
      components: { base_Header, base_Fgrid, btracombi, exp_comunicados_XM },
      props: {
        padre: { type:String, default: ''},
        id: { type: [Number, String], default: "" },             
        show: { type: [String, Boolean], default: "1" },
        accionRow: { type: [Array, Object], default: null },
        componenteTipo: { type:String, default:'FM' },
        Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
      },

      data() {
        return {
          schema:null,
          accion:'',         
          api:'exp_Comunicados_F',
          stName:'stFexp_Comunicados',
                    
          headers:{},
          dialog:false,
          disparo:false
        };
      },


      computed:{
        fallefhhr() {
          return this.$options.filters['fechahr'](this.accionRow.fallefhhr);
        }
      },


      methods: {
        ini_data() {
          console.log("DEV " + this.api + " ********************** (ini_data)");

          // guardo Schema del store en variable local
          this.schema = this.$store.state[this.stName].schema;
        
          // configuro Entorno          
          this.Entorno.grid.autoload= false;
          this.Entorno.header.titulo = "Comunicados Expedientes";
          this.Entorno.btra= JSON.parse(JSON.stringify(this.$cfg.btra_tipo["F"]));          
          this.Entorno.btra.row.extra= [
            { accion: "ver_Pdf", btn: "btn_pdf", show: 1 },
          ];
                  
          // header  
          this.headers = {
            header: [                
                { text: "Acciones", value: "acciones", sortable: false, width: "auto", slot:true },
                { text: "Fecha / Usuario", value: "fhhr", slot:true },
                { text: "Envio Compañía / Funeraria", value: "emp", slot:true },
                { text: "Envio / Tipo", value: "name_env", sortable:false, slot:true }                
              ]
          }

          // Inicializo cabeceras
          this.Entorno.grid.headers = this.set_headers();         
          this.Entorno.grid.row.expansible= true;
          this.Entorno.grid.bind['items-per-page'] = -1;
        },
       

        // muestro documento pdf
        async ver_Pdf(evt) {
          var fn_args= { accion:'ver', id:evt.row.id };

          if (this.estado== 'nuevo') {
            // obtengo campos del comunicado y los añado a los argumentos          
            var ctrls= this.get_data_comunicado(0);          
            Object.assign(fn_args, ctrls);          
          }

          // llamada a la API      
          var args = { tipo: 'fnc', accion: 'comunicados', fn_args:fn_args };                
          var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });          
          if (apiResult.sql.error) {
            this.$root.$alert.open(apiResult.sql.msg, 'error');
            return;
          }

          // abro documento
          window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");        
        },


        // evento cerrar ventana modal
        // compruebo si el mto_XM NO está en edición para poder cerrar la ventana
        cerrar() {          
          if (this.miStHijo== null || (this.miStHijo.estado!= 'editar' && this.miStHijo.estado!= 'nuevo')) {
            this.dialog= false;
            return;
          }

          this.$root.$alert.open('GUARDE O CANCELE datos para cerrar', 'info');          
        },

      },

    };

</script>


